<template>
  <div class="home">

    <div v-if="loader">
      <h1>Loaded</h1>
    </div>
    <div v-else>
      <h3>City: {{ city }}</h3>
      <h3>Strasse: {{ strasse }}</h3>
      <h3>Latt: {{ inlatt }}</h3>
      <h3>Longt: {{ inlongt }}</h3>
    </div>
    <button style="padding: 8px;margin: 16px;" @click="test">cklick</button>
    <button style="padding: 8px;margin: 16px;" @click="not">Отослать уведомление</button>
    <button style="padding: 8px;margin: 16px;" @click="notchrom">Отослать уведомление Chrome</button>
    <button style="padding: 8px;margin: 16px;" @click="navGeo">Geo</button>
<!--    <button style="padding: 8px;margin: 16px;" @click="navHistory">History</button>-->
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      loader: true,
      city: '',
      inlatt: '',
      inlongt: '',
    }
  },
  methods: {
    not() {
      console.log('ddd');
      new Notification('У Вас новое уведомление!!!', {
        body: 'Перейдите по ссылки',
        icon: 'img/icons/mstile-150x150.png',
        image: 'img/icons/mstile-150x150.png',
        badge: 'img/icons/apple-touch-icon-76x76.png'
      })
    },
    navGeo() {
      navigator.geolocation.getCurrentPosition(position => {
        this.getCityAndCountry(position)
      }, err => {
        console.log('ddd');
      }, { timeout: 7000 })
    },
    getCityAndCountry(position) {
      let apiUrl = `https://geocode.xyz/${ position.coords.latitude },${ position.coords.longitude }?json=1`
      axios.get(apiUrl).then(result => {
        console.log(result.data);
        this.city = result.data.city
        this.strasse = result.data.alt.loc[0].staddress + ' ' + result.data.alt.loc[0].stnumber
        this.inlatt = result.data.inlatt
        this.inlongt = result.data.inlongt
        this.loader = false
      }).catch(err => {
        console.log('dddddd');
        setTimeout(this.navGeo, 1000)
      })
    },
    notchrom() {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification('У Вас новое уведомление!!! С хрома', {
          body: 'Перейдите по ссылки',
          icon: 'img/icons/mstile-150x150.png',
          image: 'img/icons/mstile-150x150.png',
          badge: 'img/icons/apple-touch-icon-76x76.png',
          dir: 'ltr',
          lang: 'en-US',
          vibrate: [100, 50, 200],
          tag: 'confirm-notification',
        });
      });
    },
    test() {
      Notification.requestPermission(result => {
        if (result === 'granted') {
          console.log('reslut', result);
          new Notification('You`re subscribed to notifications!')
        }
      })
    }
  }
}
</script>
